import React from 'react';
import styles from './scss/index.module.scss';

export const Loader = () => {
  return (
    <div className={styles.LoaderHolder}>
      <div className={styles.Loader}>Loading</div>
    </div>
  );
};
