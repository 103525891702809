import * as actionTypes from '../actions/actionTypes';

import { CATEGORIES, TITLES } from '../../constants';

import { mapResults } from './logic';

const initialState = {
  loading: true,
  activeTitle: TITLES.TOP_HEADLINES,
  newsItems: null,
  searchVal: null,
  moreItems: false,
  currentPage: 1,
  activeCategory: CATEGORIES[0],
  addingMore: false,
  error: null,
  sourceList: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case actionTypes.NEWS_ITEMS:
      return {
        ...state,
        newsItems: mapResults(action.payload),
      };
    case actionTypes.CHANGE_TITLE:
      return {
        ...state,
        activeTitle: action.payload,
      };
    case actionTypes.SET_SEARCH_TERM:
      return {
        ...state,
        searchVal: action.payload,
      };
    case actionTypes.SET_MORE_NEWS:
      return {
        ...state,
        moreItems: action.payload,
      };
    case actionTypes.INC_CURRENT_PAGE:
      return {
        ...state,
        currentPage: state.currentPage + 1,
      };
    case actionTypes.RESET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: 1,
      };
    case actionTypes.ADD_MORE_NEWS:
      return {
        ...state,
        newsItems: [...state.newsItems, ...mapResults(action.payload)],
      };
    case actionTypes.SET_ACTIVE_CATEGORY:
      return {
        ...state,
        activeCategory: action.payload,
      };
    case actionTypes.ADDING_MORE_NEWS:
      return {
        ...state,
        addingMore: action.payload,
      };
    case actionTypes.SET_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case actionTypes.SET_SOURCE_LIST:
      return {
        ...state,
        sourceList: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
