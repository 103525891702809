import { useEffect, useState } from 'react';

// Hook
export const useMatchMedia = (min, max, bp) => {
  const [size, setSize] = useState(45);

  useEffect(() => {
    const mql = max
      ? window.matchMedia(`(max-width: ${max}px) and (min-width: ${min}px)`)
      : window.matchMedia(`(min-width: ${min}px)`);
    const matchMediaCB = () => {
      setSize(mql.matches ? bp : false);
    };

    matchMediaCB();

    mql.addListener(matchMediaCB);

    return () => {
      mql.removeListener(matchMediaCB);
    };
  }, [min, max, bp]);

  return size;
};
