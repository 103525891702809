export const LOADING = 'LOADING';
export const GET_NEWS_ITEMS = 'GET_NEWS_ITEMS';
export const NEWS_ITEMS = 'NEWS_ITEMS';
export const CHANGE_TITLE = 'CHANGE_TITLE';
export const SET_SEARCH_TERM = 'SET_SEARCH_TERM';
export const SET_MORE_NEWS = 'SET_MORE_NEWS';
export const INC_CURRENT_PAGE = 'INC_CURRENT_PAGE';
export const RESET_CURRENT_PAGE = 'RESET_CURRENT_PAGE';
export const ADD_MORE_NEWS = 'ADD_MORE_NEWS';
export const ADDING_MORE_NEWS = 'ADDING_MORE_NEWS';
export const SET_ACTIVE_CATEGORY = 'SET_ACTIVE_CATEGORY';
export const SET_ERROR = 'SET_ERROR';
export const GET_SOURCE_LIST = 'GET_SOURCE_LIST';
export const SET_SOURCE_LIST = 'SET_SOURCE_LIST';
