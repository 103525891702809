import * as actionTypes from './actionTypes';

import axios from 'axios';
import { getCategoryQuery } from '../../utilities';

export const END_POINT = process.env.REACT_APP_END_POINT;

const checkSourceVal = (source) => {
  return source !== 'All' ? source : 'country=us';
};

const setNewsItems = (payload) => ({
  type: actionTypes.NEWS_ITEMS,
  payload,
});

const setLoading = (payload) => ({
  type: actionTypes.LOADING,
  payload,
});

const resolvedRequest = (dispatch, results) => {
  setTimeout(() => {
    dispatch(setNewsItems(results));
  }, 300);

  setTimeout(() => {
    dispatch(setLoading(false));
  }, 800);
};

export const changeTitle = (payload) => ({
  type: actionTypes.CHANGE_TITLE,
  payload,
});

const setMoreNews = (payload) => ({
  type: actionTypes.SET_MORE_NEWS,
  payload,
});

const currentPage = () => ({
  type: actionTypes.INC_CURRENT_PAGE,
});

const resetCurrentPage = () => ({
  type: actionTypes.RESET_CURRENT_PAGE,
});

const addMoreNews = (payload) => ({
  type: actionTypes.ADD_MORE_NEWS,
  payload,
});

const addingMoreNews = (payload) => ({
  type: actionTypes.ADDING_MORE_NEWS,
  payload,
});

const setError = (payload) => ({
  type: actionTypes.SET_ERROR,
  payload,
});

const setSourceList = (payload) => ({
  type: actionTypes.SET_SOURCE_LIST,
  payload,
});

export const getNewsItems = (payload) => (dispatch) => {
  dispatch(setLoading(true));
  dispatch(resetCurrentPage());
  dispatch(setError(false));
  axios
    .get(`${END_POINT}/topHeadlines/${getCategoryQuery(payload)}`, {
      params: {
        page: 1,
      },
    })
    .then((responce) => responce.data)
    .then((data) => {
      dispatch(setMoreNews(data.totalResults > 20));
      dispatch(currentPage());
      return data.articles;
    })
    .then((articles) => resolvedRequest(dispatch, articles))
    .catch((error) => {
      dispatch(setLoading(false));
      dispatch(setError(true));
    });
};

export const getNewsItemsBySource = (payload) => (dispatch) => {
  dispatch(setLoading(true));
  dispatch(resetCurrentPage());
  dispatch(setError(false));
  const url =
    payload !== 'default'
      ? `${END_POINT}/topHeadlines/sources/${checkSourceVal(payload)}`
      : `${END_POINT}/topHeadlines/`;
  axios
    .get(url)
    .then((data) => data.data)
    .then((data) => {
      dispatch(setMoreNews(false));
      return data.articles;
    })
    .then((articles) => resolvedRequest(dispatch, articles))
    .catch(() => {
      dispatch(setLoading(false));
      dispatch(setError(true));
    });
};

export const loadMoreNews = (payload) => (dispatch) => {
  const { query, currentPageNum } = payload;

  dispatch(addingMoreNews(true));
  dispatch(setError(false));
  axios
    .get(`${END_POINT}${query}`)
    .then((data) => data.data)
    .then((data) => {
      const loadMore =
        data.totalResults > 20 * currentPageNum && currentPageNum <= 3;

      dispatch(setMoreNews(loadMore));

      setTimeout(() => {
        dispatch(currentPage());
        dispatch(addingMoreNews(false));
      }, 500);

      return data.articles;
    })
    .then((articles) => dispatch(addMoreNews(articles)))
    .catch(() => {
      dispatch(setLoading(false));
      dispatch(setError(true));
    });
};

export const searchNewsItems = (payload) => (dispatch) => {
  dispatch(setLoading(true));
  dispatch(resetCurrentPage());
  dispatch(setError(false));

  axios
    .get(`${END_POINT}/everything/${payload}?page=1`)
    .then((data) => data.data)
    .then((data) => {
      dispatch(setMoreNews(data.totalResults > 20));
      dispatch(currentPage());
      return data.articles;
    })
    .then((articles) => resolvedRequest(dispatch, articles))
    .catch(() => {
      dispatch(setLoading(false));
      dispatch(setError(true));
    });
};

export const setSearchTerm = (payload) => ({
  type: actionTypes.SET_SEARCH_TERM,
  payload,
});

export const setActiveCategory = (payload) => ({
  type: actionTypes.SET_ACTIVE_CATEGORY,
  payload,
});

export const getSourceList = () => (dispatch) => {
  axios
    .get(`${END_POINT}/sources`)
    .then((data) => data.data)
    .then((data) => {
      dispatch(setSourceList(data.sources));
    });
};
