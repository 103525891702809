import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CSSTransition } from 'react-transition-group';
import { NewsItem } from '../news-item';
import { TITLES } from '../../constants';
import { getQuery } from '../../utilities';
import { loadMoreNews } from '../../store/actions';
import newsStyles from './scss/index.module.scss';
import { useDebounce } from '../../hooks/debounce';

export const News = React.memo(() => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loading);
  const currentPage = useSelector((state) => state.currentPage);
  const moreItems = useSelector((state) => state.moreItems);
  const addingMore = useSelector((state) => state.addingMore);
  const activeCategory = useSelector((state) => state.activeCategory);
  const searchVal = useSelector((state) => state.searchVal);
  const isHeadlines = useSelector(
    (state) => state.activeTitle === TITLES.TOP_HEADLINES
  );
  const [scrollVal, setScrollVal] = useState(undefined);
  const debouncedScrollVal = useDebounce(scrollVal, 500);
  const scrollEvtBounced = useCallback(
    (scrollVal) => {
      const totalScroll = document.body.offsetHeight;
      if (
        scrollVal >= totalScroll - 2000 &&
        scrollVal > 500 &&
        !addingMore &&
        moreItems
      ) {
        dispatch(
          loadMoreNews({
            query: getQuery({
              activeCategory,
              currentPage,
              isHeadlines,
              searchVal,
            }),
            currentPageNum: currentPage,
          })
        );
      }
    },
    [
      addingMore,
      dispatch,
      moreItems,
      activeCategory,
      currentPage,
      isHeadlines,
      searchVal,
    ]
  );

  useEffect(() => {
    scrollEvtBounced(debouncedScrollVal);
  }, [debouncedScrollVal, scrollEvtBounced]);

  useEffect(() => {
    const scrollEvt = () => {
      setScrollVal(
        document.body.scrollTop || document.documentElement.scrollTop
      );
    };
    window.addEventListener('scroll', scrollEvt);

    return () => {
      window.removeEventListener('scroll', scrollEvt);
    };
  }, []);

  return (
    <CSSTransition
      mountOnEnter
      unmountOnExit
      in={!loading}
      timeout={300}
      classNames={{
        enter: newsStyles.ShowNewsEnter,
        enterActive: newsStyles.ShowNewsEnterActive,
        exit: newsStyles.CloseNewsEnter,
        exitActive: newsStyles.CloseNewsEnterActive,
      }}
    >
      <div className={newsStyles.NewsHolder}>
        <NewsItem />
      </div>
    </CSSTransition>
  );
});
