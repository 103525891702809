import { NewsOptions } from '../news-options';
import React from 'react';
import styles from './scss/index.module.scss';
import { useSelector } from 'react-redux';

export const AppHeader = () => {
  const activeTitle = useSelector((state) => state.activeTitle);
  return (
    <header className={styles.header}>
      <div>
        <NewsOptions />
        <h1>{activeTitle}</h1>
      </div>
    </header>
  );
};
