import uuidv4 from 'uuid';

export const mapResults = items => (items.map(article => {
  article.urlToImage = article.urlToImage
    ? article.urlToImage.replace(/^http:\/\//i, 'https://')
    : undefined;
  article.id = uuidv4();

  return article;
}));

