import { useDispatch, useSelector } from 'react-redux';

import React from 'react';
import brokenRobots from '../../images/broken-robot.png';
import { getNewsItems } from '../../store/actions';
import styles from './scss/index.module.scss';

const Error = (props) => {
  const activeCategory = useSelector((state) => state.activeCategory);
  const dispatch = useDispatch();
  const clickEvt = (evt) => {
    dispatch(getNewsItems(activeCategory));
  };
  return (
    <div className={styles.ErrorHolder}>
      <img src={brokenRobots} alt="No Results" />
      <p>Sorry Something when wrong!</p>
      <button type="button" onClick={clickEvt}>
        Try Again
      </button>
    </div>
  );
};

export default Error;
