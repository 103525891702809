import { AppHeader } from './components/header';
import Error from './components/error';
import { Loader } from './components/loader';
import { News } from './components/news';
import React from 'react';
import styles from './App.module.scss';
import {useSelector} from 'react-redux';

function App() {
  const loading = useSelector(state => state.loading);
  const error = useSelector(state => state.error);
  const isLoading = loading ? <Loader /> : null;
  const display = error ? <Error /> : <News />
  return (
    <div className={styles.App}>
      {isLoading}
      <AppHeader />
      {display}
    </div>
  );
}

export default App;
