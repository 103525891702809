export const CATEGORIES = [
  'all',
  'business',
  'entertainment',
  'general',
  'health',
  'science',
  'technology',
];

export const TITLES = {
  TOP_HEADLINES: 'Top Headlines',
  SEARCH: 'Search',
};

export const BP_SIZES = {
  TABLET_SMALL: 'tabletSmall',
  DESKTOP: 'desktop',
  LARGE: 'large',
};
