export const capitalize = (copy) =>
  copy.charAt(0).toUpperCase() + copy.slice(1);

export const ellipsis = (copy, maxCharacter) =>
  copy.length > maxCharacter ? `${copy.substring(0, maxCharacter)}...` : copy;

export const getCategoryQuery = (category) =>
  category !== 'all' ? `category/${category}` : '';

const getCatQuery = (cat, currentPage) => {
  return cat !== 'all'
    ? `/topHeadlines/${getCategoryQuery(cat)}/?page=${currentPage}`
    : `/topHeadlines/?page=${currentPage}`;
};

export const getQuery = ({
  activeCategory,
  currentPage,
  isHeadlines,
  searchVal,
}) =>
  isHeadlines
    ? getCatQuery(activeCategory, currentPage)
    : `/everything/${searchVal}/?page=${currentPage}`;
