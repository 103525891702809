import React, { useEffect, useState } from 'react';

import { BP_SIZES } from '../../constants';
import brokenRobots from '../../images/broken-robot.png';
import { ellipsis } from '../../utilities';
import missingImg from '../../images/image-not-found-1038x576.jpg';
import moment from 'moment';
import newsItemStyles from './scss/index.module.scss';
import { useMatchMedia } from '../../hooks/match-media';
import { useSelector } from 'react-redux';

export const NewsItem = React.memo(() => {
  const [bpMaxCount, setBpMaxCount] = useState(BP_SIZES.TABLET_SMALL);
  const smallBp = useMatchMedia(320, 700, BP_SIZES.TABLET_SMALL);
  const midBp = useMatchMedia(700, 1500, BP_SIZES.DESKTOP);
  const lgBp = useMatchMedia(1500, undefined, BP_SIZES.LARGE);
  const newsItems = useSelector((state) => state.newsItems);
  const searchTerm = useSelector((state) => state.searchVal);
  const getTime = (time) => moment(time).format('MMM Do YYYY');
  const imgError = (e) => {
    e.target.src = missingImg;
  };
  const publishedInfo = (newsItem) => {
    if (newsItem.publishedAt) {
      return (
        <span className={newsItemStyles.Time}>
          {getTime(newsItem.publishedAt)}
        </span>
      );
    }

    return null;
  };
  const newsTitle = (newsItem) => {
    if (newsItem.title) {
      return (
        <h2>
          <a href={newsItem.url} target="_blank" rel="noreferrer noopener">
            {ellipsis(newsItem.title, bpMaxCount)}
          </a>
        </h2>
      );
    }
    return null;
  };
  const newsDescription = (newsItem) => {
    if (newsItem.description) {
      return <p>{newsItem.description}</p>;
    }

    return null;
  };
  const newsItemsDisplay = () => {
    if (newsItems && newsItems.length > 0) {
      return newsItems.map((newsItem) => (
        <div key={newsItem.id} className={newsItemStyles.NewsItem}>
          <div className={newsItemStyles.ImageHolder}>
            <a href={newsItem.url} target="_blank" rel="noreferrer noopener">
              <div>
                <img
                  src={newsItem.urlToImage || missingImg}
                  alt="display"
                  onError={imgError}
                />
              </div>
            </a>
          </div>
          <div className={newsItemStyles.NewsItemContent}>
            {publishedInfo(newsItem)}
            {newsTitle(newsItem)}
            {newsDescription(newsItem)}
          </div>
          {newsItem.source.name && (
            <a
              href={newsItem.url}
              target="_blank"
              rel="noreferrer noopener"
              className={newsItemStyles.Source}
            >
              Source: {newsItem.source.name}
            </a>
          )}
        </div>
      ));
    }

    if (newsItems) {
      return (
        <div className={newsItemStyles.NoResults}>
          <img src={brokenRobots} alt="No Results" />
          <p>No Results Found For Your Search Of {searchTerm}!</p>
        </div>
      );
    }

    return null;
  };

  useEffect(() => {
    const [smCount, midCount, largeCount] = [45, 60, 88];
    let count;

    if (smallBp) {
      count = smCount;
    } else if (midBp) {
      count = midCount;
    } else {
      count = largeCount;
    }

    setBpMaxCount(count);
  }, [smallBp, midBp, lgBp]);

  return newsItemsDisplay();
});
