export const actionTypes = {
  UPDATE_SEARCH: 'Update Search',
  UPDATE_SOURCE: 'UPDATE_SOURCE',
  TOGGLE_CAT_CHANGE: 'TOGGLE_CAT_CHANGE',
};

function toggleSearch(state, payload) {
  const searchOptionsCopy = state.formItems.searchOptions.map((option) => {
    if (payload === option.value) {
      option.checked = true;
    } else {
      option.checked = false;
    }

    return option;
  });

  if (payload === 'search') {
    return {
      ...state,
      search: true,
      activeSource: 'All',
      formItems: { ...state.formItems, searchOptions: searchOptionsCopy },
    };
  }

  return {
    ...state,
    search: false,
    activeSource: 'All',
    formItems: { ...state.formItems, searchOptions: searchOptionsCopy },
  };
}

export const formReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_SEARCH:
      return toggleSearch(state, action.payload);
    case actionTypes.UPDATE_SOURCE:
      return {
        ...state,
        activeSource: action.payload,
      };
    case actionTypes.TOGGLE_CAT_CHANGE:
      return {
        ...state,
        resetCat: action.payload,
      };
    default:
      return state;
  }
};
