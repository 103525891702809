import { CATEGORIES, TITLES } from '../../constants';
import React, { Fragment, useEffect, useReducer, useRef } from 'react';
import { actionTypes, formReducer } from './reducer';
import {
  changeTitle,
  getNewsItems,
  getNewsItemsBySource,
  getSourceList,
  searchNewsItems,
  setActiveCategory,
  setSearchTerm,
} from '../../store/actions';
import { useDispatch, useSelector } from 'react-redux';

import { capitalize } from '../../utilities';
import formStyles from './scss/index.module.scss';

export const NewsOptions = () => {
  const inputEl = useRef(null);
  const dispatch = useDispatch();
  const activeCategory = useSelector((state) => state.activeCategory);
  const sourceList = useSelector((state) => state.sourceList);
  const [formState, dispatchForm] = useReducer(formReducer, {
    search: false,
    activeSource: 'All',
    resetCat: false,
    formItems: {
      searchOptions: [
        {
          type: 'radio',
          name: 'searchOptions',
          value: 'category',
          checked: true,
        },
        {
          type: 'radio',
          name: 'searchOptions',
          value: 'search',
          checked: false,
        },
        {
          type: 'radio',
          name: 'searchOptions',
          value: 'source',
          checked: false,
        },
      ],
      category: {
        options: [...CATEGORIES],
      },
    },
  });
  const [activeOption] = formState.formItems.searchOptions.filter(
    (v) => v.checked
  );
  const toggleSearchOption = (evt) => {
    const val = evt.target.value;

    dispatchForm({
      type: actionTypes.UPDATE_SEARCH,
      payload: val,
    });
    dispatch(
      changeTitle(val !== 'search' ? TITLES.TOP_HEADLINES : TITLES.SEARCH)
    );

    if (val === 'category' && formState.resetCat) {
      if (activeCategory === 'all') {
        dispatch(getNewsItems('all'));
      } else {
        dispatch(setActiveCategory('all'));
      }

      dispatchForm({
        type: actionTypes.TOGGLE_CAT_CHANGE,
        payload: false,
      });
    }
  };
  const submitSearch = (evt) => {
    const isValid = evt.target.checkValidity();
    evt.preventDefault();

    if (isValid) {
      const val = inputEl.current.value;
      dispatch(setSearchTerm(val));
      dispatch(searchNewsItems(val));
      dispatchForm({
        type: actionTypes.TOGGLE_CAT_CHANGE,
        payload: true,
      });
    }
  };
  const changeEvt = (evt) => {
    dispatch(setActiveCategory(evt.target.value));
  };
  const changeSourceEvt = (evt) => {
    const val = evt.target.value;

    dispatchForm({
      type: actionTypes.UPDATE_SOURCE,
      payload: val,
    });
    dispatch(getNewsItemsBySource(val));
    dispatchForm({
      type: actionTypes.TOGGLE_CAT_CHANGE,
      payload: true,
    });
  };
  const displaySearchOptions = () => {
    if (activeOption.value === 'category') {
      return (
        <select
          onChange={changeEvt}
          value={activeCategory}
          className={formStyles.Select}
        >
          {formState.formItems.category.options.map((category) => (
            <option key={category} value={category}>
              {capitalize(category)}
            </option>
          ))}
        </select>
      );
    } else if (activeOption.value === 'source') {
      return (
        <select
          onChange={changeSourceEvt}
          value={formState.activeSource}
          className={formStyles.Select}
        >
          <option key="all" value="default">
            Pick a Source
          </option>
          {sourceList.map((source) => (
            <option key={source.id} value={source.id}>
              {source.name}
            </option>
          ))}
        </select>
      );
    }

    //Default Search
    return (
      <Fragment>
        <input
          type="search"
          placeholder="e.g. Bitcoin"
          ref={inputEl}
          required
        />
        <button type="submit"></button>
      </Fragment>
    );
  };

  useEffect(() => {
    dispatch(getNewsItems(activeCategory));
  }, [dispatch, activeCategory]);

  useEffect(() => {
    dispatch(getSourceList());
  }, [dispatch]);

  return (
    <form onSubmit={submitSearch} noValidate className={formStyles.Form}>
      <div className={formStyles.OptionsHolder}>
        {formState.formItems.searchOptions.map((searchOption) => (
          <label
            key={searchOption.value}
            htmlFor={searchOption.value}
            className={formStyles.SearchOptionLabel}
          >
            <input
              id={searchOption.value}
              type={searchOption.type}
              name={searchOption.name}
              value={searchOption.value}
              checked={searchOption.checked}
              onChange={toggleSearchOption}
            />
            <span></span>
            {searchOption.value}
          </label>
        ))}
      </div>
      <div
        className={
          !formState.search ? formStyles.SelectHolder : formStyles.SearchHolder
        }
      >
        {displaySearchOptions()}
      </div>
    </form>
  );
};
